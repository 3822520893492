<template>
  <form>
    <div class="form-group">
      <input
        type="text"
        class="form-control username-input input-icon"
        id="usernameInput"
        aria-describedby="usernameHelp"
        placeholder="Enter username"
        autocomplete="on"
        v-model="form.username"
      >
    </div>
    <div class="form-group">
      <input
        :type=" showPassword? 'text': 'password' "
        class="form-control password-input input-icon"
        id="exampleInputPassword1"
        placeholder="Password"
        v-model="form.password"
      >
      <img src="@/assets/img/login/visibility-off.svg" v-if="showPassword" @click="toggleShow">
      <img src="@/assets/img/login/visibility-on.svg" v-if="!showPassword" @click="toggleShow">
    </div>
    <button type="submit" class="btn btn-primary" @click.prevent="authUser">Log In</button>
    <small class="form-text text-muted forget-pw" @click="$emit('forget-pw')">Forget Password?</small>
  </form>
</template>

<script>
import axios from "axios";
export default {
  data: function() {
    return {
      showPassword: false,
      form: {
        username: null,
        password: null
      }
    };
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    async authUser() {
      try {
        let currentThis = this;
        let user = await axios({
          url: "http://testdeployd.aezypay.com/users/login",
          method: "post",
          withCredentials: true,
          data: this.form
        })
        .then(function(res) {
          window.$cookies.set("username", currentThis.form.username);
          currentThis.$router.push("/admin");
        })
        .catch(function(error) {
          console.log(error);
        });
      } catch (err) {
        console.error("error", err);
      }
    }
  }
};
</script>

<style lang="scss">
$line-color: #e6e7e8;
$placeholder-color: #585956;

form {
  // text-align: left;
  padding: 2vw;
  .form-group {
    // color: $text-color;
    margin-bottom: 40px;
    text-align: left;

    label {
      outline: none;
    }

    input[type="text"],
    input[type="password"] {
      border: none;
      outline: none;
      border-bottom: 1px solid $line-color;
      border-radius: 0;
      box-shadow: none;
    }
    .input-icon {
      padding: 0px 0px 2px 45px;
      vertical-align: middle;
    }

    .username-input {
      background: url("../../assets/img/login/email.svg") no-repeat;
      background-size: 3vh;
    }
    .password-input {
      background: url("../../assets/img/login/password.svg") no-repeat;
      background-size: 2.5vh;
      background-position-y: 0;
    }

    .form-check-label {
      color: rgba(#3f599a, 0.5);
      font-size: 14px;
    }

    ::-webkit-input-placeholder {
      color: $placeholder-color;
      font-size: 13px;
    }
    ::-moz-placeholder {
      color: $placeholder-color;
      font-size: 13px;
    }
    :-ms-input-placeholder {
      color: $placeholder-color;
      font-size: 13px;
    }
    ::placeholder {
      color: $placeholder-color;
      font-size: 13px;
    }
  }
  .form-group {
    position: relative;
    img {
      position: absolute;
      width: 6.5%;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }

  button[type="submit"] {
    background-color: #3f599a;
    color: rgba(#ffffff, 0.8);
    border: none;
    outline: none;
    border-radius: 15px;
    padding: 5px 50px;
    cursor: default;

    &:focus {
      box-shadow: none;
    }
  }

  .forget-pw {
    cursor: pointer;
    font-size: 11px;
  }
}
</style>
