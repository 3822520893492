<template>
  <form class="forget-pw-form" @submit.prevent="submitRequest">
    <div class="form-group">
      <input
        type="email"
        class="form-control email-input"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        placeholder="Enter email"
        autocomplete="off"
        v-model="form.email"
      >
    </div>
    <button type="submit" class="btn btn-primary">Reset Password</button>
    <small class="form-text text-muted return" @click="$emit('return')">Return to previous page</small>
  </form>
</template>

<script>
import axios from "axios";

export default {
  data: function() {
    return {
      showPassword: false, 
      form: {
        email: null
      }
    };
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    async submitRequest() {
      try {
        let res = await axios({
          url: 'http://testdeployd.aezypay.com/reset-pw',
          method: 'post',
          data: this.form
        })
        this.form.email = null
        this.$emit('return')

      } catch (error) {
        console.error('error', error)
      }
    }
  }
};
</script>

<style lang="scss">
$line-color: #e6e7e8;
$placeholder-color: #585956;

.forget-pw-form {
  // text-align: left;
  padding: 30px 2vw;
  .form-group {
    // color: $text-color;
    margin-bottom: 83px;
    text-align: left;

    label {
      outline: none;
    }

    input[type="email"] {
      border: none;
      outline: none;
      border-bottom: 1px solid $line-color;
      border-radius: 0;
      box-shadow: none;

      padding: 0px 0px 2px 50px;
      vertical-align: middle;
    }

    .email-input {
      background: url("../../assets/img/login/email.svg") no-repeat;
      background-size: 3vh;
    }
    .password-input {
      background: url("../../assets/img/login/password.svg") no-repeat;
      background-size: 2.5vh;
      background-position-y: 0;
    }

    ::-webkit-input-placeholder {
      color: $placeholder-color;
      font-size: 13px;
    }
    ::-moz-placeholder {
      color: $placeholder-color;
      font-size: 13px;
    }
    :-ms-input-placeholder {
      color: $placeholder-color;
      font-size: 13px;
    }
    ::placeholder {
      color: $placeholder-color;
      font-size: 13px;
    }
  }
  .form-group {
    position: relative;
    img {
      position: absolute;
      width: 6.5%;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }

  button[type="submit"] {
    background-color: #3f599a;
    color: rgba(#ffffff, 0.8);
    border: none;
    outline: none;
    border-radius: 15px;
    padding: 5px 50px;
    cursor: default;

    &:focus {
      box-shadow: none;
    }
  }

  .return {
    cursor: pointer;
    font-size: 11px;
  }
}
</style>
