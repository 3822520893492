<template>
  <div class="login">
    <div class="card-1">
      <div class="circle-1"></div>
      <div class="circle-2"></div>
      <img src="@/assets/img/login/admin-logo.png" alt="logo" class="logo">
      <div class="login-form">
        <h1>{{ formTitle }}</h1>
        <p
          v-if="forgetPassword"
          class="text-muted small-text"
        >Forgot your password? Please enter your email address. You will receive a link to reset password via provided email.</p>
        <Login v-if="!forgetPassword" @forget-pw="togglePassword"/>
        <ForgetPassword v-else @return="togglePassword"/>
        <small class="copyright">Copyright Aezypay Pte Ltd 2019</small>
      </div>
    </div>
  </div>
</template>

<script>
import Login from "@/components/login/Login.vue";
import ForgetPassword from "@/components/login/ForgetPassword.vue";

export default {
  name: "login",
  head: {
    title: { inner: "Login" }
  },
  data: function() {
    return {
      forgetPassword: false
    };
  },
  computed: {
    formTitle() {
      return !this.forgetPassword ? "Login Page" : "Forget Password";
    }
  },
  components: {
    Login,
    ForgetPassword
  }, 
  methods: {
    togglePassword(){
      this.forgetPassword = !this.forgetPassword
    }
  }
};
</script>

<style lang="scss">
@import "../assets/css/project";

.login {
  height: 100vh;
  background: url("../assets/img/login/bg-1.png") no-repeat center;
  background-size: cover;

  margin-left: (-$grid-gutter-width / 2);
  margin-right: (-$grid-gutter-width / 2);

  display: flex;
  justify-content: center;
  align-items: center;

  // card design
  .card-1 {
    width: 80vw;
    height: 80vh;
    background: url("../assets/img/login/img-1.png") no-repeat left,
      url("../assets/img/login/bg-2.png") no-repeat center;
    background-size: 45%, cover;

    border-radius: 30px;
    box-shadow: 10px 10px 28px 0px rgba(102, 102, 102, 0.25);
  }

  // positioning within card
  .card-1 {
    position: relative;
    .circle-1 {
      height: 2vw;
      width: 2vw;
      border-radius: 50%;
      background-color: #6479ae;

      position: absolute;
      right: 10%;
      top: 8%;
    }
    .circle-2 {
      height: 5vw;
      width: 5vw;
      border-radius: 50%;
      background-color: #6479ae;

      position: absolute;
      right: 3%;
      top: 15%;
    }
    .logo {
      position: absolute;
      // width: 20%;
      width: 26vh;
      // right: 15.8%;
      right: 13.8vw;
      top: 14.5%;
    }
    .login-form {
      position: absolute;
      right: 11%;
      top: 30%;

      h1 {
        // margin-top: 35%;
        margin-top: 12vh;
        position: relative;
        z-index: 1;
        &:before,
        &:after {
          position: absolute;
          top: 45%;
          overflow: hidden;
        }
      }
    }
  }

  // login-form
  .card-1 {
    .login-form {
      height: 60%;
      width: 30%;
      border-radius: 15px;
      box-shadow: 0px 0px 28px 0px rgba(102, 102, 102, 0.25);

      h1 {
        font-size: 16px;
        color: #668ed6;
        overflow: hidden;
        text-transform: uppercase;
        text-align: center;

        &:before,
        &:after {
          width: 20%;
          height: 1.5px;
          content: "\a0";
          background-color: #668ed6;
        }

        &:before {
          margin-left: -24%;
          text-align: right;
        }
        &:after {
          margin-left: 5%;
        }
      }
      .small-text{
        padding: 0 2vw;
        font-size: 12px;
      }

      .copyright {
        font-size: 10px;
        color: #bbbdbf;
      }
    }
  }
}
</style>
